<template>
  <BlueHeaderModal @close="closePushModal">
    <template v-slot:header>
      <h3 v-if="!isEditMode">{{ $t("footerSendModal.titlePlanCreate") }}</h3>
      <h3 v-if="isEditMode">{{ $t("footerSendModal.titlePlanSync") }}</h3>
    </template>
    <template v-slot:body v-if="!isLoading">
      <div class="modal-body">
        <div>
          <label class="plan-title">
            {{ $t("footerSendModal.planTitle") }}
            <input type="text" v-model="userPlanTitle" />
          </label>
        </div>
        <div class="add-a-copy">
          <Toggle v-model="saveCopyToPlans" />
          <p>{{ $t("footerSendModal.addCopyToPlans") }}</p>
        </div>

        <button class="button is-blue" :disabled="!userPlanTitle" @click="pushPlanToTD('calendar')">
          {{ isEditMode ? $t("footerSendModal.titleCalendarSync") : $t("footerSendModal.titleCalendarCreate") }}
        </button>
        <button class="button is-underlined" :disabled="!userPlanTitle" @click="pushPlanToTD('plans')">
          {{ $t("footerSendModal.send") }}
        </button>
      </div>
    </template>
    <template v-slot:body v-else>
      <div class="plan-loader">
        <Lottie :options="defaultOptions" :height="300" :width="300" v-on:animCreated="handleAnimation" />
        <p>{{ $t("footerSendModal.creating") }}</p>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <a target="_blank" @click="learnMore" href="https://blog.trainerday.com/my-plans-vs-calendar-a823252be2e9">
          <span>{{ $t("footerSendModal.learnMore.learn") }}</span> {{ $t("footerSendModal.learnMore.about") }}
        </a>
      </div>
    </template>
  </BlueHeaderModal>
</template>
<script>
import createPlanName from "@/mixins/createPlanName"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import Toggle from "@/components/Toggle.vue"
import * as animationData from "@/assets/images/loader.json"
import Lottie from "vue-lottie"
import { LEARN_MORE_ABOUT_MY_PLANS_VS_CALENDAR } from "@/services/eventNames"
import { ROUTE_NAMES } from "@/defaults"

export default {
  name: "SavePlanModal",
  components: { Toggle, Lottie },
  data() {
    return {
      userPlanTitle: "",
      saveCopyToPlans: false,
      isLoading: false,
      defaultOptions: { animationData: animationData.default }
    }
  },
  computed: {
    ...mapState(["isEmbedded"]),
    ...mapState("plan", ["values", "config"]),
    ...mapGetters("plan", [
      "getBlockConfigValue",
      "getPlanConfigValue",
      "resultEvents",
      "resultFirstPrimaryEvent",
      "getStrongestBlockPlanType"
    ]),
    ...mapGetters(["getFirstName"]),

    isEditMode() {
      return !!this.$route.query.historyId
    },
    historyId() {
      return this.$route.query.historyId
    }
  },
  beforeMount() {
    const previouslySavedName = this.getPlanConfigValue("name")

    if (previouslySavedName) {
      this.userPlanTitle = previouslySavedName
    } else {
      this.userPlanTitle = createPlanName(
        this.values,
        this.getFirstName,
        this.getStrongestBlockPlanType,
        this.getBlockConfigValue("customPlan")
      )
    }
  },
  methods: {
    ...mapActions("plan", ["pushPlan"]),
    ...mapMutations(["setProfileFactsValue"]),
    ...mapMutations("plan", ["setPlanConfigValue"]),

    closePushModal() {
      this.$emit("close")
      // this.planTitle = null
    },
    learnMore() {
      this.$trackEvent({ name: LEARN_MORE_ABOUT_MY_PLANS_VS_CALENDAR })
    },
    handleAnimation(anim) {
      this.anim = anim
    },
    async pushPlanToTD(destination) {
      this.isLoading = true
      const { mode } = this.$route.params
      let historyId = this.historyId
      const plansRes = await this.pushPlan({
        name: this.userPlanTitle,
        historyId,
        mode,
        destination,
        saveCopyToPlans: this.saveCopyToPlans,
      })

      historyId = plansRes.historyId
      const { planSlug } = plansRes
      this.isLoading = false
      const { isEmbedded } = this
      if (isEmbedded) {
        this.$emit("redirect", { isEmbedded, planSlug, destination })
      }
      this.$emit("close")

      if (!isEmbedded) {
        // for development
        void this.$router.push({ name: "history" })
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
::v-deep {
  .blue-header-modal-container {
    max-width: 700px;
    min-width: 500px;
    width: 100%;
    @include media("<tablet") {
      max-width: unset;
      min-width: unset;
    }
  }
  .blue-header-modal-header {
    padding: 30px;
    margin-bottom: 0;
    .field {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      color: white;
      margin-bottom: 0;
    }
  }

  .blue-header-modal-body {
    padding: 10px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    a {
      align-self: center;
    }

    span {
      font-weight: bold;
    }
  }

  .blue-header-modal-footer {
    align-items: center;
    //gap: 60px;
    padding: 0 30px 30px;
    font-size: 16px;

    @include media("<tablet") {
      gap: 30px;
    }

    .button {
      padding: 1em 2em;
      text-transform: none;
      border-radius: 3px;
      font-size: 16px;

      @include media("<tablet") {
        padding: 1em;
      }
    }

    a {
      color: black;
      text-decoration: underline;
    }
  }
}
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.plan-title {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin: 15px 0;
  gap: 10px;
  input {
    padding: 10px;
    font-size: 16px;
  }
}
.add-a-copy {
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
}
a {
  text-decoration: none !important;
  span {
    font-weight: bold;
    text-decoration: underline;
  }
}
.is-underlined {
  border: none;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
}
.plan-loader {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  p {
    font-weight: bold;
    font-size: 16px;
    color: $dark-blue;
  }
}
</style>
